<template>
	<div class="all-rule-list">
		<el-breadcrumb style="margin-bottom: 10px;" separator="/">
			<el-breadcrumb-item :to="{ path: '/role/role' }">角色权限</el-breadcrumb-item>

		</el-breadcrumb>
		<el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
			<el-form-item>
				<el-input style="width: 300px;" v-model="name" placeholder="输入角色名称关键字搜索">
					<el-button slot="append" icon="el-icon-search" @click="getDataList()"></el-button>
				</el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="addTab()">新增</el-button>
				<!-- 				<el-button  type="primary"  @click="test()" >新增</el-button> -->
				<el-button type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0">批量删除</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="dataList"  v-loading="dataListLoading" @selection-change="selectionChangeHandle" style="width: 100%;">
			<el-table-column type="selection" :selectable="checkSelectable" header-align="center" align="center" width="50">
			</el-table-column>
			<el-table-column prop="name" header-align="center" align="center" width="280" label="角色名称">
				<template slot-scope="scope">
					<el-button type="text" size="small" @click="gotoRoleInfo(scope.row)">{{ scope.row.name }}</el-button>
				</template>
			</el-table-column>
			<el-table-column prop="des" header-align="center" align="center" label="备注">
			</el-table-column>
			<!-- 			<el-table-column prop="creater" header-align="center" width="100" align="center" label="创建者">
			</el-table-column> -->
			<el-table-column  header-align="center" align="center" width="180" label="创建时间">
				<template slot-scope="scope">
									<div style="padding: 11px;">{{scope.row.createTime}}</div>
									</template>
			</el-table-column>
			<el-table-column fixed="right" header-align="center" align="center" width="100" label="操作">
				<template slot-scope="scope">
					<el-button  type="text" size="small" @click="gotoRoleEdit(scope.row)">编辑</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
		 :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
		</el-pagination>
	</div>
</template>

<script>
	import Qs from 'qs'
	export default {
		data() {
			return {
				dataForm: {
					roleName: ''
				},
				name: '',
				dataList: [],
				pageIndex: 1,
				pageSize: 10,
				totalPage: 0,
				dataListLoading: false,
				dataListSelections: [],
				addOrUpdateVisible: false
			}
		},
		components: {
			// AddOrUpdate
		},
		created() {
			this.getDataList()
		},
		computed: {


		},
		methods: {
			// 获取数据列表
			getDataList() {
			
				let obj = {
				intParam1: JSON.parse(sessionStorage.userInfo).type,
					stringParam2: this.pageIndex,
					stringParam3: this.pageSize,
					stringParam1: this.name,
				
				}
				this.dataListLoading = true
				this.$httpAes({
					url: this.$httpAes.adornUrl('/role/page'),
					method: "post",
					data: obj,
					contentType:'json',
					headers: {
						'Content-Type': 'application/json',
					   				
					},
				}).then(({
					data
				}) => {
					this.dataList = data.data;
					this.totalPage = data.totalCount

					this.dataListLoading = false
				})
			},

			// 每页数
			sizeChangeHandle(val) {
				this.pageSize = val
				this.pageIndex = 1
				this.getDataList()
			},
			// 当前页
			currentChangeHandle(val) {
				this.pageIndex = val
				this.getDataList()
			},
			// 多选
			selectionChangeHandle(val) {
				this.dataListSelections = val
			},
			checkSelectable(row, index) {
				if (row.id == 'd651b9e7858b48efa93caaaac48c5205' || row.id == 'e89325119fa411e89e43525400557295' || row.id ==
					'e89325119fa411e89e43525400557297' || row.id == 'e89325119fa411e89e43525400557296' || row.id ==
					'e89325119fa411e89e43525400557298' || row.id == 'e89325119fa411e89e43525400557299'|| row.id == '67423005b597411d92a3102eef0dc794'|| row.id == 'e89325119fa411e89e43525400557300') {
					return false;
				} else {
					return true;
				}
			},
			addTab(targetName) {
				// 				 this.addRoleFlag = true
				// 				 this.mainTabsActiveName = 'add-role'
				this.$router.push({
					path: '/role/add'
				})
			},
			test() {
				let Menu = {
					'userId': this.$cookie.get('userId'),
					id: 177,
					code: "customer",
					icon: "",
					isMenu: 1,
					isOpen: 1,
					levels: 1,
					name: "客户管理",
					num: 3,
					pcode: "",
					pcodes: "0",
					status: 1,
					tips: "",
					url: ""
				}
				this.$httpAes({
					url: this.$httpAes.adornUrl('menu/update'),
					method: 'post',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',

						'token': this.$cookie.get('token'),
					},
					data: Qs.stringify(Menu)
				}).then(({
					data
				}) => {
					if (data.status) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.dialogVisible = false
								this.$emit('refreshDataList')
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				})
			},
			gotoRoleInfo(info) {
				// 							 this.roleInfoFlag = true
				// 							 this.roleInfo = info
				// 							 this.mainTabsActiveName = 'info'
				sessionStorage.setItem('roleInfo', JSON.stringify(info))
				const url = this.$router.resolve({
					path: '/role/info'
				})
				window.open(url.href,"_blank")
				
			},
			gotoRoleEdit(info) {
				// 							 this.roleEditFlag = true
				// 							 this.roleInfo = info
				// 							 this.mainTabsActiveName = 'edit'
				sessionStorage.setItem('roleInfo', JSON.stringify(info))
				this.$router.push({
					path: '/role/edit'
				})
			},

			// 删除
			deleteHandle(id) {
				var userIds = id ? [id] : this.dataListSelections.map(item => {
					return item.id
				})
				

				this.$confirm(`确定进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$httpAes({
						url: this.$httpAes.adornUrl('role/delete'),
						method: "post",
						data: {stringParam1:userIds.join(',')},
						contentType:'json',
						headers: {
							'Content-Type': 'application/json',
						   				
						},
						
					}).then(({
						data
					}) => {
						if (data.status) {
							this.$message({
								message: '操作成功',
								type: 'success',
								duration: 1500,
								onClose: () => {
									this.getDataList()
								}
							})
						} else {
							this.$message.error(data.msg)
							this.getDataList()
						}
					})
				}).catch(() => {})
			}
		}
	}
</script>
<style lang="scss">
	.all-rule-list {
		.search-top {
			padding-bottom: 20px;
			overflow: hidden;

			.search-btn {
				font-size: 20px;
				cursor: pointer;
			}
		}

		.el-dialog {
			width: 600px;
		}

	}
</style>
